
/******** navbar header *********/

window.addEventListener("load", (event) => {


const bar_btn = document.getElementById('BAR_BTN');
  const mobileMenu = document.getElementById('mobileMenu')

  bar_btn.addEventListener('click',function()
   {
          mobileMenu.classList.toggle('scale-y-0');

          mobileMenu.classList.toggle('hidden');



    }
  );


});



// When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

// Get the header
var header = document.getElementById("header");

// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  if (window.pageYOffset > sticky) {
    header.classList.add("fixed-header");
  } else {
    header.classList.remove("fixed-header");
  }
}



/********  Fin navbar header *********/
