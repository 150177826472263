/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
// si on veut importer d'autres css on le fera dans site.scss
import './styles/site/site.scss';
import './styles/aos.css';


import './js/site/skeleton.js';


// importer les js que j'utilise
//particules pour les boutton
//import './js/anime.min.js';
//import './js/particles.js';
//import './js/button-effect.js';

import { registerReactControllerComponents } from '@symfony/ux-react';
registerReactControllerComponents(require.context('./react/controllers', true, /\.(j|t)sx?$/));